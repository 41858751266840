<template>
  <b-card
    class="align-items-center p-2 w-100 m-0 h-100 "
    no-body
  >

    <b-row
      class="mt-1 text-center"
    >
      <b-col>
        <h4>
          Join <span class="text-primary"><b>
            <b-link @click="handleTournamentViewClick">{{ tournament?.name }}</b-link>

          </b></span> tournament?
        </h4>
      </b-col>
    </b-row>

    <b-row class="mt-1 text-center">
      <b-col>
        <h6 class="text-muted mb-0">
          Invited by
          <b-link @click="handleOrganizerClick">{{
              tournament?.organizer?.name
          }}
          </b-link>
        </h6>
      </b-col>
    </b-row>

    <b-row
      v-if="localInvite?.participant?.participant_type === TournamentParticipantType.TEAM"
      class="mt-1 text-center flex-column"
    >
      <b-col>
        <h5>  <b-avatar
          :src="localInvite?.participant?.participant?.avatar_image"
          :text="localInvite?.participant?.participant?.name.substring(0,2) ||localInvite?.participant?.name.substring(0,2) "
          class="mr-1"
          size="32"
        />{{ localInvite?.participant?.name }}</h5>
        <h6 class="text-muted mb-0">
          Team
        </h6>
      </b-col>

      <b-col
        v-if="localInvite?.participant?.participant_type === TournamentParticipantType.TEAM && !localInvite?.participant?.participant && localInvite?.tournament?.status_id < TournamentStatusType.IN_PROGRESS"
        class="mt-1"
      >
        <b-button
          size="sm"
          variant="warning"
          class="flex-grow-1"
          @click="handleClaimTeam"
        >
          <b-spinner
            v-if="isClaiming"
            small
          />
          <span v-else>Claim this team</span></b-button>
      </b-col>
    </b-row>

    <b-row
      class="mt-1 mb-1  mt-auto"
    >
      <b-col
        class="d-flex justify-content-center mt-1"
        sm="12"
      >
        <b-button
          size="sm"
          variant="primary"
          class="flex-grow-1"
          @click="handleTournamentViewClick"
        >
          <feather-icon
            icon="EyeIcon"
            size="12"
            class="mr-1"
          />
          <span class="mr-25 align-middle">Tournament info</span>
        </b-button>
      </b-col>
    </b-row>

    <b-row
      v-if="localInvite?.participant?.participant"
      class="d-flex align-content-between"
    >
      <b-col>
        <b-button
          class="btn-icon"
          variant="success"
          size="sm"
          :disabled="isLoadingAccept || isLoadingDecline"
          @click.stop="approveInvitation(true)"
        >
          <b-spinner
            v-if="isLoadingAccept"
            small
          />
          <feather-icon
            v-else
            icon="CheckIcon"
          />
        </b-button>

        <b-button
          class="btn-icon ml-1"
          variant="danger"
          size="sm"
          :disabled="isLoadingAccept || isLoadingDecline"
          @click.stop="approveInvitation(false)"
        >
          <b-spinner
            v-if="isLoadingDecline"
            small
          />
          <feather-icon
            v-else
            icon="XIcon"
          />
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mt-1 text-center">
      <b-col>
        <dismissible-message-box
          :show="showNotifications"
          :variant="notificationType"
          :auto-dismiss="3000"
        >{{ notificationMessage }}
        </dismissible-message-box>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BButton, BCard, BCol, BLink, BRow, BSpinner,
} from 'bootstrap-vue'
import TournamentParticipantType from '@/constants/TournamentParticipantType'
import MessageType from '@/constants/MessageType'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import TournamentStatusType from '@/constants/TournamentStatusType'

export default {
  components: {
    BAvatar,
    DismissibleMessageBox,
    BLink,
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
  },
  props: {
    invite: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      MessageType,
      TournamentStatusType,
      TournamentParticipantType,

      localInvite: this.invite,
      tournament: this.invite?.tournament,

      notificationMessage: null,
      showNotifications: false,
      notificationType: MessageType.SUCCESS,
      invitationLocal: this.invitation,
      isLoadingAccept: false,
      isLoadingDecline: false,
      isClaiming: false,
    }
  },
  computed: {

  },
  watch: {
    invite(newInvite) {
      this.localInvite = newInvite
      this.tournament = newInvite?.tournament
    },
  },
  methods: {
    async approveInvitation(accept) {
      this.isLoadingAccept = accept
      this.isLoadingDecline = !accept

      this.showNotifications = false

      const { errors } = await this.$api.tournament.respondToTournamentInvite(
        this.invite.token,
        accept,
      )

      this.isLoadingAccept = false
      this.isLoadingDecline = false

      if (errors && errors.length > 0) {
        this.notificationType = MessageType.ERROR
        this.notificationMessage = 'There was an error saving your decision. Please try again or contact our support'
        this.showNotifications = true
        return
      }

      this.notificationType = MessageType.SUCCESS
      this.notificationMessage = accept === true
        ? 'You are participating in this tournament'
        : 'You have declined the invitation to join the tournament'
      this.showNotifications = true

      setTimeout(() => {
        this.$emit('onApproveTournamentInvitation', this.invite)
      }, 2000)
    },
    handleTournamentViewClick() {
      const resolvedRoute = this.$router.resolve({
        name: 'tournament-overview',
        params: {
          id: Number(this.tournament?.id),
          game: this.tournament?.discipline?.slug,
        },
      })

      window.open(resolvedRoute.href, '_blank')
    },
    handleOrganizerClick() {
      const resolvedRoute = this.$router.resolve(
        {
          name: 'organizer-profile',
          params: { id: this.tournament?.organizer.id },
        },
      )

      window.open(resolvedRoute.href, '_blank')
    },

    async handleClaimTeam() {
      this.isClaiming = true

      this.showNotifications = false

      const { errors } = await this.$api.team.claimTeamMembership(this.invite.token)

      this.isClaiming = false

      if (errors && errors.length > 0) {
        this.notificationType = MessageType.ERROR
        this.notificationMessage = 'There was an error claiming team. Please try again or contact our support'
        this.showNotifications = true
        return
      }

      this.notificationType = MessageType.SUCCESS
      this.notificationMessage = 'You have claimed the team'
      this.showNotifications = true

      this.$emit('onClaimTeam', this.invite)
    },
  },
}
</script>
