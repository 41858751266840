<template>
  <b-card
    class="text-center p-2 w-100 h-100"
    no-body
  >
    <b-row class="text-center">
      <b-col>
        <b-avatar
          :src="invite?.team?.avatar_image"
          size="82"
        />
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col>
        <h5>{{ invite?.team?.name }}</h5>
        <h6 class="text-muted mb-0">
          Team
        </h6>
      </b-col>
    </b-row>

    <b-row
      class="mb-1 d-flex justify-content-center"
    >
      <b-col
        class="d-flex justify-content-center mt-1"
        sm="12"
        md="6"
      >
        <b-button
          size="sm"
          variant="primary"
          @click="handleViewClick"
        >
          <feather-icon
            icon="EyeIcon"
            size="12"
            class="mr-1"
          />
          <span class="align-middle">Profile</span>
        </b-button>
      </b-col>
    </b-row>

    <b-row class="d-flex align-content-between">
      <b-col>
        <b-button
          class="btn-icon"
          variant="success"
          size="sm"
          :disabled="isLoadingAccept || isLoadingDecline"
          @click.stop="approveInvitation(true)"
        >
          <b-spinner
            v-if="isLoadingAccept"
            small
          />
          <feather-icon
            v-else
            icon="CheckIcon"
          />
        </b-button>

        <b-button
          class="btn-icon ml-1"
          variant="danger"
          size="sm"
          :disabled="isLoadingAccept || isLoadingDecline"
          @click.stop="approveInvitation(false)"
        >
          <b-spinner
            v-if="isLoadingDecline"
            small
          />
          <feather-icon
            v-else
            icon="XIcon"
          />
        </b-button>

      </b-col>
    </b-row>

    <b-row class="mt-1 text-center">
      <b-col>
        <dismissible-message-box
          :show="showNotifications"
          :variant="notificationType"
          :auto-dismiss="3000"
        >{{ notificationMessage }}
        </dismissible-message-box>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BAvatar, BButton, BCard, BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import MessageType from '@/constants/MessageType'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'

export default {
  components: {
    DismissibleMessageBox,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BSpinner,
  },
  props: {
    invite: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      MessageType,

      notificationMessage: null,
      showNotifications: false,
      notificationType: MessageType.SUCCESS,
      invitationLocal: this.invitation,
      isLoadingAccept: false,
      isLoadingDecline: false,
    }
  },
  computed: {

  },
  methods: {
    async approveInvitation(accept) {
      this.isLoadingAccept = accept
      this.isLoadingDecline = !accept

      this.showNotifications = false

      const { errors } = await this.$api.team.respondToTeamInvite(
        this.invite.token,
        accept,
      )

      this.isLoadingAccept = false
      this.isLoadingDecline = false

      if (errors && errors.length > 0) {
        this.notificationType = MessageType.ERROR
        this.notificationMessage = 'There was an error saving your decision. Please try again or contact our support'
        this.showNotifications = true
        return
      }

      this.notificationType = MessageType.SUCCESS
      this.notificationMessage = accept === true
        ? `You are now a member of the team ${this.invite?.team?.name}`
        : `You have declined the invitation to join the team ${this.invite?.team?.name}`
      this.showNotifications = true

      setTimeout(() => {
        this.$emit('onApproveTeamInvitation', this.invite)
      }, 2000)
    },
    handleViewClick() {
      const resolvedRoute = this.$router.resolve({
        name: 'team-profile',
        params: {
          id: Number(this.invite?.team?.id),
        },
      })

      window.open(resolvedRoute.href, '_blank')
    },
  },
}
</script>
