var render = function () {
  var _vm$invite, _vm$invite$team, _vm$invite2, _vm$invite2$team;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "text-center p-2 w-100 h-100",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    staticClass: "text-center"
  }, [_c('b-col', [_c('b-avatar', {
    attrs: {
      "src": (_vm$invite = _vm.invite) === null || _vm$invite === void 0 ? void 0 : (_vm$invite$team = _vm$invite.team) === null || _vm$invite$team === void 0 ? void 0 : _vm$invite$team.avatar_image,
      "size": "82"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', [_c('h5', [_vm._v(_vm._s((_vm$invite2 = _vm.invite) === null || _vm$invite2 === void 0 ? void 0 : (_vm$invite2$team = _vm$invite2.team) === null || _vm$invite2$team === void 0 ? void 0 : _vm$invite2$team.name))]), _c('h6', {
    staticClass: "text-muted mb-0"
  }, [_vm._v(" Team ")])])], 1), _c('b-row', {
    staticClass: "mb-1 d-flex justify-content-center"
  }, [_c('b-col', {
    staticClass: "d-flex justify-content-center mt-1",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.handleViewClick
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "EyeIcon",
      "size": "12"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Profile")])], 1)], 1)], 1), _c('b-row', {
    staticClass: "d-flex align-content-between"
  }, [_c('b-col', [_c('b-button', {
    staticClass: "btn-icon",
    attrs: {
      "variant": "success",
      "size": "sm",
      "disabled": _vm.isLoadingAccept || _vm.isLoadingDecline
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.approveInvitation(true);
      }
    }
  }, [_vm.isLoadingAccept ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _c('feather-icon', {
    attrs: {
      "icon": "CheckIcon"
    }
  })], 1), _c('b-button', {
    staticClass: "btn-icon ml-1",
    attrs: {
      "variant": "danger",
      "size": "sm",
      "disabled": _vm.isLoadingAccept || _vm.isLoadingDecline
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.approveInvitation(false);
      }
    }
  }, [_vm.isLoadingDecline ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _c('feather-icon', {
    attrs: {
      "icon": "XIcon"
    }
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "mt-1 text-center"
  }, [_c('b-col', [_c('dismissible-message-box', {
    attrs: {
      "show": _vm.showNotifications,
      "variant": _vm.notificationType,
      "auto-dismiss": 3000
    }
  }, [_vm._v(_vm._s(_vm.notificationMessage) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }