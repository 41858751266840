var render = function () {
  var _vm$tournament, _vm$tournament2, _vm$tournament2$organ, _vm$localInvite, _vm$localInvite$parti, _vm$localInvite2, _vm$localInvite2$part, _vm$localInvite2$part2, _vm$localInvite3, _vm$localInvite3$part, _vm$localInvite3$part2, _vm$localInvite4, _vm$localInvite4$part, _vm$localInvite5, _vm$localInvite5$part, _vm$localInvite6, _vm$localInvite6$part, _vm$localInvite7, _vm$localInvite7$part, _vm$localInvite8, _vm$localInvite8$tour, _vm$localInvite9, _vm$localInvite9$part;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "align-items-center p-2 w-100 m-0 h-100 ",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    staticClass: "mt-1 text-center"
  }, [_c('b-col', [_c('h4', [_vm._v(" Join "), _c('span', {
    staticClass: "text-primary"
  }, [_c('b', [_c('b-link', {
    on: {
      "click": _vm.handleTournamentViewClick
    }
  }, [_vm._v(_vm._s((_vm$tournament = _vm.tournament) === null || _vm$tournament === void 0 ? void 0 : _vm$tournament.name))])], 1)]), _vm._v(" tournament? ")])])], 1), _c('b-row', {
    staticClass: "mt-1 text-center"
  }, [_c('b-col', [_c('h6', {
    staticClass: "text-muted mb-0"
  }, [_vm._v(" Invited by "), _c('b-link', {
    on: {
      "click": _vm.handleOrganizerClick
    }
  }, [_vm._v(_vm._s((_vm$tournament2 = _vm.tournament) === null || _vm$tournament2 === void 0 ? void 0 : (_vm$tournament2$organ = _vm$tournament2.organizer) === null || _vm$tournament2$organ === void 0 ? void 0 : _vm$tournament2$organ.name) + " ")])], 1)])], 1), ((_vm$localInvite = _vm.localInvite) === null || _vm$localInvite === void 0 ? void 0 : (_vm$localInvite$parti = _vm$localInvite.participant) === null || _vm$localInvite$parti === void 0 ? void 0 : _vm$localInvite$parti.participant_type) === _vm.TournamentParticipantType.TEAM ? _c('b-row', {
    staticClass: "mt-1 text-center flex-column"
  }, [_c('b-col', [_c('h5', [_c('b-avatar', {
    staticClass: "mr-1",
    attrs: {
      "src": (_vm$localInvite2 = _vm.localInvite) === null || _vm$localInvite2 === void 0 ? void 0 : (_vm$localInvite2$part = _vm$localInvite2.participant) === null || _vm$localInvite2$part === void 0 ? void 0 : (_vm$localInvite2$part2 = _vm$localInvite2$part.participant) === null || _vm$localInvite2$part2 === void 0 ? void 0 : _vm$localInvite2$part2.avatar_image,
      "text": ((_vm$localInvite3 = _vm.localInvite) === null || _vm$localInvite3 === void 0 ? void 0 : (_vm$localInvite3$part = _vm$localInvite3.participant) === null || _vm$localInvite3$part === void 0 ? void 0 : (_vm$localInvite3$part2 = _vm$localInvite3$part.participant) === null || _vm$localInvite3$part2 === void 0 ? void 0 : _vm$localInvite3$part2.name.substring(0, 2)) || ((_vm$localInvite4 = _vm.localInvite) === null || _vm$localInvite4 === void 0 ? void 0 : (_vm$localInvite4$part = _vm$localInvite4.participant) === null || _vm$localInvite4$part === void 0 ? void 0 : _vm$localInvite4$part.name.substring(0, 2)),
      "size": "32"
    }
  }), _vm._v(_vm._s((_vm$localInvite5 = _vm.localInvite) === null || _vm$localInvite5 === void 0 ? void 0 : (_vm$localInvite5$part = _vm$localInvite5.participant) === null || _vm$localInvite5$part === void 0 ? void 0 : _vm$localInvite5$part.name))], 1), _c('h6', {
    staticClass: "text-muted mb-0"
  }, [_vm._v(" Team ")])]), ((_vm$localInvite6 = _vm.localInvite) === null || _vm$localInvite6 === void 0 ? void 0 : (_vm$localInvite6$part = _vm$localInvite6.participant) === null || _vm$localInvite6$part === void 0 ? void 0 : _vm$localInvite6$part.participant_type) === _vm.TournamentParticipantType.TEAM && !((_vm$localInvite7 = _vm.localInvite) !== null && _vm$localInvite7 !== void 0 && (_vm$localInvite7$part = _vm$localInvite7.participant) !== null && _vm$localInvite7$part !== void 0 && _vm$localInvite7$part.participant) && ((_vm$localInvite8 = _vm.localInvite) === null || _vm$localInvite8 === void 0 ? void 0 : (_vm$localInvite8$tour = _vm$localInvite8.tournament) === null || _vm$localInvite8$tour === void 0 ? void 0 : _vm$localInvite8$tour.status_id) < _vm.TournamentStatusType.IN_PROGRESS ? _c('b-col', {
    staticClass: "mt-1"
  }, [_c('b-button', {
    staticClass: "flex-grow-1",
    attrs: {
      "size": "sm",
      "variant": "warning"
    },
    on: {
      "click": _vm.handleClaimTeam
    }
  }, [_vm.isClaiming ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _c('span', [_vm._v("Claim this team")])], 1)], 1) : _vm._e()], 1) : _vm._e(), _c('b-row', {
    staticClass: "mt-1 mb-1  mt-auto"
  }, [_c('b-col', {
    staticClass: "d-flex justify-content-center mt-1",
    attrs: {
      "sm": "12"
    }
  }, [_c('b-button', {
    staticClass: "flex-grow-1",
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.handleTournamentViewClick
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "EyeIcon",
      "size": "12"
    }
  }), _c('span', {
    staticClass: "mr-25 align-middle"
  }, [_vm._v("Tournament info")])], 1)], 1)], 1), (_vm$localInvite9 = _vm.localInvite) !== null && _vm$localInvite9 !== void 0 && (_vm$localInvite9$part = _vm$localInvite9.participant) !== null && _vm$localInvite9$part !== void 0 && _vm$localInvite9$part.participant ? _c('b-row', {
    staticClass: "d-flex align-content-between"
  }, [_c('b-col', [_c('b-button', {
    staticClass: "btn-icon",
    attrs: {
      "variant": "success",
      "size": "sm",
      "disabled": _vm.isLoadingAccept || _vm.isLoadingDecline
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.approveInvitation(true);
      }
    }
  }, [_vm.isLoadingAccept ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _c('feather-icon', {
    attrs: {
      "icon": "CheckIcon"
    }
  })], 1), _c('b-button', {
    staticClass: "btn-icon ml-1",
    attrs: {
      "variant": "danger",
      "size": "sm",
      "disabled": _vm.isLoadingAccept || _vm.isLoadingDecline
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.approveInvitation(false);
      }
    }
  }, [_vm.isLoadingDecline ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _c('feather-icon', {
    attrs: {
      "icon": "XIcon"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-row', {
    staticClass: "mt-1 text-center"
  }, [_c('b-col', [_c('dismissible-message-box', {
    attrs: {
      "show": _vm.showNotifications,
      "variant": _vm.notificationType,
      "auto-dismiss": 3000
    }
  }, [_vm._v(_vm._s(_vm.notificationMessage) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }